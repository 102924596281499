import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from '../components/seo'
import {DangerousHTML} from '../components/contentful/html'

const AboutPage = ({data}) => (
	<Layout>
		<Helmet>
			<body className="about-page" />
		</Helmet>
		<div className="inset">
			<SEO {...data.page?.metadata} />
			<DangerousHTML>{data.page?.body.html.content}</DangerousHTML>
		</div>
	</Layout>
)

export default AboutPage

export const query = graphql`
	{
		page: contentfulBasicPage(title: {eq: "About Us"}) {
			body {
				html: childMarkdownRemark {
					content: html
				}
			}
			metadata {
				...ContentfulMetadataFragment
			}
		}
	}
`
